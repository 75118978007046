<template>
    <div v-if="drag" class="menu-drop-zone" :class="{dragover: dragOver, active: drag}"
        @drop.stop="drop($event)"
        @dragover.prevent="dragOver=true"
        @dragenter.prevent
        @dragleave.prevent="dragOver=false"
    > </div>
  
</template>

<script>
import useMenuDrag from '@admin/composable/MenuDrag';
import { ref } from 'vue';
export default {
    name: "MenuDropZone",
    props: {
        parentCode: String,
        index: Number
    },
    setup(props) {
        const { onDrop,drag } = useMenuDrag();
        const dragOver = ref(false);

        const drop = (event) => {
            onDrop(event,props.parentCode, props.index);
            dragOver.value=false;
        }

        return {
            dragOver,
            drop,
            drag
        }
    }
}
</script>

<style scoped>
.menu-drop-zone{
    min-height: 0.5rem;
    background: green;
    z-index: -100;
    opacity: 0;
}
.menu-drop-zone.active{
    z-index: 10;
    opacity: 0.3;
}
.menu-item.dragged .menu-drop-zone.active{
    z-index: -100;
    opacity: 0;
    display: none;
}
.drop-position{
    margin: -0.5rem 0;
}
</style>